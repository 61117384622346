<template>
  <div class="wrapper">提示：该应用只支持手机端！</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  components: {}
};
</script>

<style scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 40%;
  font-size: 30px;
}
</style>
