<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <!-- <div class="webIcon" @click="toWeb"></div> -->
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
    </div>
    <div class="figure"></div>
    <div class="logoText"></div>
    <div class="downloadBox">
      <div class="qrcodeBorder">
        <img class="qrcodeBox" :src="qrImg" alt="" />
      </div>
      <div class="installSuggestion"></div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toWeb() {
      window.open("https://d2xtj2pb46a9pz.cloudfront.net");
    },
    toTg() {
      window.open("https://t.me/haijiaoshequ99999");
    },
    toPotato() {
      window.open("https://dljpt6.org/haijiaoshequ99");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    position: fixed;
    left: 202px;
    top: 61px;
    height: 102px;
    width: 376px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .contactBox {
    position: fixed;
    right: 80px;
    top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .webIcon {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/webIcon.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .figure {
    height: 912.5px;
    width: 858px;
    background: url("./../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 100px;
    top: 76px;
  }
  .logoText {
    height: 361px;
    width: 788.5px;
    background: url("./../../../assets/images/pc/logoText.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 202px;
    top: 233px;
  }
  .downloadBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 276px;
    bottom: 167px;
    .qrcodeBorder {
      height: 250px;
      width: 250px;
      background-color: #3262ef;
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 4px solid #3262ef;
      box-shadow: 0 0 30px #5628f5;
      .qrcodeBox {
        height: 240px;
        width: 240px;
        background-color: white;
      }
    }
    .installSuggestion {
      height: 250px;
      width: 486px;
      background: url("./../../../assets/images/pc/installSuggestion.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
