/*
 * title: 判断设备类型
 * @Author: 王枫叶
 * @Date: 2020-10-13 17:47:47
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-11-12 11:58:23
 */
export default function(context) {
  const userAgent = process.server
    ? context.req.headers["user-agent"]
    : navigator.userAgent;
  return (function() {
    const ua = userAgent;
    const isUc = /(?:UCBrowser)/.test(ua);
    const isWindowsPhone = /(?:Windows Phone)/.test(ua) && !isUc;
    const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
    const isAndroid = /(?:Android)/.test(ua) || isUc;
    const isFireFox = /(?:Firefox)/.test(ua);
    const isOPPO = /(?:OPPO)/.test(ua);
    const isTablet =
      /(?:iPad|PlayBook)/.test(ua) ||
      (isAndroid && !/(?:Mobile)/.test(ua)) ||
      (isFireFox && /(?:Tablet)/.test(ua));
    const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
    const isWx = /MicroMessenger/.test(ua);
    const isPc = !isPhone && !isAndroid && !isSymbian;
    return {
      userAgent,
      isOPPO,
      isTablet,
      isPhone,
      isAndroid,
      isPc,
      isWx
    };
  })();
}
